<template>
  <div v-loading="loading" class="shared-detail">
    <el-row
      class="title"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <h3 class="m-0">
        NỘI DUNG HIỂN THỊ TRANG BANNER: {{ page ? page.label.toUpperCase() : '' }}
      </h3>
    </el-row>
    <el-row class="shared-tabs">
      <el-tabs v-model="activeTab">
        <el-tab-pane
          v-for="position in listPositions"
          :key="position.id"
          :name="`${position.id}`"
        >
          <div slot="label">
            {{ `Banner ${position.id}` }}
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <page-contents
      v-if="currentPosition"
      :key="activeTab"
      :page-info="page"
      :position="currentPosition"
    />
    <div>
    </div>
  </div>
</template>

<script>
import PageContents from './components/Contents.vue'
import { getBanners } from '@/services/banner'

export default {
  name: 'PageDetail',
  components: {
    PageContents
  },
  data() {
    return {
      loading: false,
      activeTab: '',
      page: null,
      total: 0
    }
  },
  computed: {
    listPositions() {
      return this.page ? this.page.meta.positions : []
    },
    currentPosition() {
      return this.listPositions.find((p) => `${p.id}` === this.activeTab)
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      this.visibleUpdate = false
      getBanners({ page: 1, per_page: 100 }).then((response) => {
        this.page = response.result.find((p) => p.id === this.$route.params.id)
        this.activeTab = `${this.page.meta.positions[0].id}`
        this.loading = false
      }).catch(() => {
        this.page = {}
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.shared-detail {
  .title {
    padding: 15px;
    background-color: #630104;
    color: white;
  }
  .shared-tabs {
    padding: 0 15px 15px 15px;
    background-color: #630104;
    color: white;
  }
}
</style>
<style lang="scss">
.shared-detail {
  .shared-tabs {
    .el-tabs__item {
      color: white;
      &:hover {
        color: #409eff;
      }
      &.is-active {
        color: #409eff;
      }
    }
  }
}
</style>
